import { CollectionInfo } from './collection-info.model';
import { IsccSelfDeclarationDocument } from './iscc-self-declaration-document.model';
import { TradingDocument } from './trading-document.model';

export class AddressVisit {
  date: string;
  collections: CollectionInfo[];
  tradingDocuments: TradingDocument[];
  isccDocuments: IsccSelfDeclarationDocument[];
  invoiceIds: number[];
}
